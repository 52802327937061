import React from "react"
import styled from "styled-components"
import AddToCart from "./AddToCart"

const AddToCartWizardContainer = styled.div`
  height: auto;
  margin-top:${(props) => (props.category === "Set" ? '40px': '0px')};
  background: transparent;
  > div{
    background:transparent;
  }
`

const AddToCartWizard = (props) =>{
  
  const { toggleVariable, setToggleVariable } = props;
  return(
    <AddToCartWizardContainer category={props.category}>
    
     <AddToCart 
     {...props} 
     toggleVariable={toggleVariable}
     setToggleVariable={setToggleVariable}
     />
    </AddToCartWizardContainer>
  )
}

export default AddToCartWizard