import React, { useState, useEffect } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import { isBrowser } from "../services/core/browser"
import Recommendation from "../components/QuizResults/Recommendation"
import SingleProductsBanner from "../components/QuizResults/SingleProductsBanner"
import ResultTable from "../components/QuizResults/ResultTable"


const QuizResultPage = ({ location, data, pageContext }) => {
  const path = location.pathname
  const { page, sets, singleProducts} = data
  let { shopifyProducts } = data

  for (let shopifyProduct of shopifyProducts.nodes) {
    shopifyProduct.images = shopifyProduct.media.map(mediaItem => mediaItem.image)
  }

  const getDefaultSet = () => {
    if (sets.nodes) {
      return sets.nodes.find((set) => {
        if(set.quizSetId != null){
        return set.quizSetId.toLowerCase() === "a"
        }
      })
    }
  }
  const [result, setResult] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [recommendation, setRecommendation] = useState(getDefaultSet())
  const [recommendationBundles, setRecommendationBundles] = useState([])

  const { metaTitle, metaDescription, socialMediaImage} = page

  const allProductReviews = pageContext.productReviews

  let sharingImage = false
  let productWithContents = []

  if (socialMediaImage && socialMediaImage.file) {
    sharingImage = socialMediaImage.file.url
  }

  if (singleProducts.nodes && shopifyProducts.nodes) {
    productWithContents = shopifyProducts.nodes.filter((product) => {
      let content = singleProducts.nodes.find(
        (content) => content.slug === product.handle
      )
      let productReview;

      if (allProductReviews) {
        productReview = allProductReviews.find((review) => review.productHandle === product.handle)
      }

      if (content) {
        product.setDescription = content.setDescription
        product.subscriptionDiscountText = content.subscriptionDiscountText
        product.order = content.order
        product.recommendedSubscription = content.recommendedSubscription
        product.review = productReview
        return product
      }
    })
  }

  useEffect(() => {
    if (isBrowser()) {
      const currentUrl = new URL(window.location.href)
      const encodedSearchParams = currentUrl.search.replace("?t=", "")

      let searchParams

      try {
        searchParams = atob(decodeURIComponent(encodedSearchParams))
      } catch (error) {
        console.error(error)
      }

      if (searchParams) {
        let data = JSON.parse(searchParams)
        const recommendedSetId = data.recommended?.replace("set_", "")
        let recommendedProduct = sets.nodes.find((set) =>{
          if (
            set.quizSetId != null &&
            recommendedSetId.includes(set.quizSetId.toLowerCase())
          ) {
            return set
          }
      })

        if (recommendedProduct) {
          setRecommendation(recommendedProduct)
        }
        setResult(data)
      }
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (shopifyProducts.nodes) {
      let bundleProductSlugs = recommendation.bundleProducts.map(
        ({ slug }) => slug
      )
      let setBundleShopifyProducts = shopifyProducts.nodes.filter((product) =>
        bundleProductSlugs.includes(product.handle)
      )
      setRecommendationBundles(setBundleShopifyProducts)
    }
  }, [isLoading])

  return (
    <Layout quizResult={true}>
      <Seo
        path={path}
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
        image={sharingImage}
      />
      <Recommendation
        {...page}
        recommendation={recommendation}
        result={result}
        products={recommendationBundles}
        loading={isLoading}
        reviews={allProductReviews}
      />
      <SingleProductsBanner />
      {result && (
        <ResultTable {...page} result={result} pageUrl={location.href} />
      )}
    </Layout>
  )
}

export default QuizResultPage

export const pageQuery = graphql`
  query QuizResultPageQuery($slug: String!) {
    page: contentfulQuizResults(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
      suggestedSetCopy
      solutionDetailsTitle
      disclaimerTitle
      disclaimerCopy
      copyToClipboard
    }
    sets: allContentfulSet(filter: {quizSetId: {regex: "/[A-Z]/"}}) {
      nodes {
        ...contentfulSetFragment
      }
    }
    singleProducts: allContentfulProduct {
      nodes {
        id
        slug
        order
        recommendedSubscription
        subscriptionDiscountText
        setDescription {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    shopifyProducts: allShopifyProduct {
      nodes {
        ...shopifyProductFragment
      }
    }
  }
`
