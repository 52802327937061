import React, { useRef } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { isBrowser } from "../../services/core/browser"

const Background = styled.div`
  padding: 60px 30px 60px;
  background-color:${colors.white};
  @media (min-width: ${breakpoints.md}) {
    padding: 60px 60px 60px;
   
  }
`

const Table = styled.div`
  border: 1px solid ${colors.charcoal};
  width: 100%;
  max-width: 720px;
  margin: 0 auto;

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    flex-wrap: wrap;
  }
`

const TableData = styled.div`
  min-height: 50px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.charcoal};
  :last-of-type {
    border-bottom: none;
  }

  @media (min-width: ${breakpoints.md}) {
    width: 50%;
    border-bottom: none;
    border-top: 1px solid ${colors.charcoal};
    padding: 6px 20px;
    :first-child,
    :nth-child(2) {
      border-top: none;
    }

    :nth-child(odd) {
      border-right: 1px solid ${colors.charcoal};
    }
  }
`

const Label = styled.div`
  ${fonts.sofiaPro};
  font-size: 12px;
  margin-right: 10px;
  line-height: 1.15;
`

const Answer = styled.div`
  ${fonts.sofiaProBold};
  font-size: 10px;
  max-width: 200px;
  text-align: right;
  line-height: 1.15;
`

const Disclaimer = styled.div`
  text-align: center;
  max-width: 490px;
  margin: auto;
`

const DisclaimerTitle = styled.div`
  ${fonts.garamondBoldItalic};
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 3px;
`

const DisclaimerCopy = styled.div`
  font-size: 14px;
  line-height: 1.3;
`

const CopyButtonContainer = styled.div`
  margin: 40px auto 60px;
  display: flex;
  justify-content: center;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    margin: 30px auto 60px;
  }
`

const CopyEmail = styled.div`
  ${fonts.sofiaProBold};
  font-size: 12px;
  cursor: pointer;
  color: ${colors.charcoal};
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding-bottom: 3px;
  border-bottom: 1px solid ${colors.charcoal};
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  line-height: 1.07;
  letter-spacing: 1px;

  :hover {
    color: ${colors.purple};
    border-color: ${colors.purple};
  }

  textarea {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    
  }
`

const ResultTable = (props) => {
  const { result, disclaimerCopy, disclaimerTitle, copyToClipboard, pageUrl } = props
  let emailRef = useRef(null)

  const tableData = [
    { key: "hair_type", label: "Hair type" },
    { key: "hair_concerns", label: "Hair concern" },
    { key: "scalp_condition", label: "Scalp condition" },
    { key: "wash_frequency", label: "Wash frequency" },
    { key: "flake_description", label: "Flake appearance" },
    { key: "flake_occurrence", label: "Flake frequency" },
    { key: "travel_frequency", label: "Travel" },
    { key: "exercise_frequency", label: "Exercise" },
  ]



  const copyUrl = (e) => {
    e.preventDefault()
    if (isBrowser()) {
      if (emailRef) {
        emailRef.select();
        emailRef.setSelectionRange(0, 99999); 
        document.execCommand("copy");
      }
    }
  }

  return (
    <Background>


      <Disclaimer>
        <DisclaimerTitle>{disclaimerTitle}</DisclaimerTitle>
        <DisclaimerCopy>{disclaimerCopy}</DisclaimerCopy>
      </Disclaimer>
    </Background>
  )
}

export default ResultTable
