import React from 'react'
import styled from "styled-components"
import colors from '../../styles/colors'
import breakpoints from "../../styles/breakpoints"
import ButtonDark from "../../components/Core/Buttons/ButtonDark"

const SingleProductsContainer = styled.div`
  padding: 0;
  @media (min-width: ${breakpoints.sm}) {
    padding: 60px 60px;
  }
`
const InformationBanner = styled.div`  
  background-color: ${colors.purple};
  padding: 88px 32px 124px;
  @media(min-width: ${breakpoints.air}){
    
    max-width: 70%;
    margin: auto;
  }
`

const Title = styled.h2`
  font-size: 18px;
  max-width: 600px;
  line-height: 1.15;
  margin: 0 auto 40px;
  text-align: center;
  font-weight: 900;
  @media (min-width: ${breakpoints.md}) {
    font-size: 32px;
    letter-spacing: -0.4px;
    margin-bottom: 60px;
  }

`

const SingleProductsBanner = (props) => {

  return (
    <SingleProductsContainer>
        <InformationBanner>
      <Title>WANT TO TRY THESE PRODUCTS OUT ON THEIR OWN? NO PROBLEM.</Title>
      <ButtonDark href="/products">BUY PRODUCTS INDIVIDUALLY</ButtonDark>
      </InformationBanner>
    </SingleProductsContainer>
  )
}

export default SingleProductsBanner
